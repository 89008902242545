import React, { useContext, useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import ApiContext from "../../Context/ApiContext";

const Protected = () => {
  const { url, VerifyToken } = useContext(ApiContext); // Use the URL from context
  const token = localStorage.getItem("token");

  const [isVerified, setIsVerified] = useState(null); // State to store verification result
  const [loading, setLoading] = useState(true); // State to manage loading

  useEffect(() => {
    const handleVerification = async () => {
      if (token !== null) {
        const response = await VerifyToken(token); 
        if (response && response.status === 200) {
          setIsVerified(true); 
        } else {
          alert("Please Login Again");
          setIsVerified(false); 
        }
      } else {
        alert("Please login first");
        setIsVerified(false);
      }
      setLoading(false); 
    };

    handleVerification();
  }, [token, url, VerifyToken]); 

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while verifying token
  }

  if (isVerified) {
    return <Outlet />; // Render the protected content if verified
  } else {
    return <Navigate to="/admin/login" />; // Redirect to login if not verified
  }
};

export default Protected;