import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CenterLogin from './Components/Login/Center/CenterLogin';
import './output.css';
import AdminLogin from './Components/Login/Admin/AdminLogin';
import Dashboard from './Components/Dashboard/Dashboard';
import StudentRegistration from './Components/StudentManager/StudentRegistration/StudentRegistration';
import StudentSearch from './Components/StudentManager/StudentSearch/StudentSearch';
import ChangePassword from './Components/ChangePass/ChangePassword';
import ManageSubCenter from './Components/SubcenterManage/ManageSubCenter/ManageSubCenter';
import Wallet from './Components/Wallet/Wallet';
import ApiState from './Context/ApiState';
import Protected from './Components/Assets/Protected';
import RegistrationFrom from './Components/Assets/Print/RegistrationFrom';


function App() {
  return (
    <ApiState>
      <Router>
        <Routes>
          <Route path="*" element={<CenterLogin />} />
          <Route path="/" element={<CenterLogin />} />
          <Route path="/login" element={<CenterLogin />} />
          <Route path="/print" element={<RegistrationFrom />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          {/* Protected Routes */}
          <Route path="/auth" element={<Protected />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route
              path="onlineregistration"
              element={<StudentRegistration />}
            />
            <Route path="searchstudent" element={<StudentSearch />} />
            <Route path="changepassword" element={<ChangePassword />} />
            <Route path="subcentermanage" element={<ManageSubCenter />} />
            <Route path="wallet" element={<Wallet />} />
          </Route>
        </Routes>
      </Router>
    </ApiState>
  );
}

export default App;
