import React from 'react'
import Sidebar from '../Assets/Sidebar';
import Home from '../Assets/Home';
import Navbar from '../Assets/Navbar';

const Wallet = () => {

const handleSubmit = async () => {
  let token = "";
  const response1 = await fetch("http://localhost:1337/api/auth/local", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      identifier: "test@test.com",
      password: "test123",
    }),
  });

  const data1 = await response1.json()
  token = data1.jwt
  console.log(token)


 const response = await fetch("http://localhost:1337/api/users/me", {
   headers: {
     "Authorization": `Bearer ${token}`,
     "Content-Type": "application/json"
   },
 });

 const data = await response.json();
 alert(data)
 console.log(data); // This will include the role
 return data;
}
// const handleSubmit = async () => {
//   const response = await fetch(
//     "http://localhost:1337/api/auth/local/register",
//     {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         username: "ic@123",
//         email: "ic@gmail.com",
//         password: "yourpassword",
//       }),
//     }
//   );

//   const data = await response.json();
//   console.log(data);
//   alert(JSON.stringify(data));
// };


  return (
    <div>
      <Navbar />
      <div className="flex">
        <Sidebar />
        <div className="bg-red-50 w-100">
          <button onClick={handleSubmit} className='m-4 p-2 text-red-500 bg-red-200 rounded border-red-600'>Click me</button>
        </div>
      </div>
    </div>
  );
}

export default Wallet