import React, { useContext, useEffect, useState } from "react";
import ApiContext from "../../Context/ApiContext";

const RegistrationFromStudent = () => {
  const { url, statesdata, session, courses, token } = useContext(ApiContext);

  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [stream, setStreams] = useState();

  const [formData, setFormData] = useState({
    studentName: "",
    admission_date: "2023-09-09",
    course: null,
    stream: null,
    semester: null,
    fee_status: "Pending",
    document_status: "Pending",
    fatherName: "",
    motherName: "",
    dateOfBirth: "",
    gender: "",
    category: "",
    aadhaarNumber: "",
    passportNo: "",
    isEmployed: "",
    employerName: "",
    designation: "",
    contactNumber: "",
    emailAddress: "",
    fathersContactNo: "",
    mothersContactNo: "",
    country: "",
    nationality: "",
    state: "",
    district: "",
    address: "",
    pinCode: "",
    examSecondaryYearOfPassing: "",
    examSecondaryBoardUniversity: "",
    examSecondaryPercentage: "",
    examSecondarySubjects: "",
    examSrSecondaryYearOfPassing: "",
    examSrSecondaryBoardUniversity: "",
    examSrSecondaryPercentage: "",
    examSrSecondarySubjects: "",
    examGraduationYearOfPassing: "",
    examGraduationBoardUniversity: "",
    examGraduationPercentage: "",
    examGraduationSubjects: "",
    examPostGraduationYearOfPassing: "",
    examPostGraduationBoardUniversity: "",
    examPostGraduationPercentage: "",
    examPostGraduationSubjects: "",
    examOthersYearOfPassing: "",
    examOthersBoardUniversity: "",
    examOthersPercentage: "",
    examOthersSubjects: "",
    session: null,
    modeOfStudy: "",
    admissionType: "",
    hostelFacility: null,
    registrationFee: null,
    entranceFee: null,
    enrollmentFee: null,
    tuitionFee: null,
    universityDevelopmentFee: null,
    hostelFee: null,
    lateFee: null,
    totalDeposit: null,
    studentOf: null  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const fetchStreams = async () => {
      if (formData.course) {
        try {
          const response = await fetch(
            `${url}/api/courses/${formData.course}?populate=streams`
          );
          const result = await response.json();
          setStreams(result.data.streams || []);
        } catch (error) {
          console.error("Error fetching streams:", error);
        }
      } else {
        setStreams([]); // Clear streams if no course selected
      }
    };

    fetchStreams();
  }, [formData.course]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== "image/jpeg") {
        alert("Please upload a JPEG image.");
        return;
      }

      if (file.size > 500 * 1024) {
        alert("File size should not exceed 500KB.");
        return;
      }

      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const uploadImage = async () => {
    const imageData = new FormData();
    imageData.append("files", selectedImage, selectedImage.name);

    try {
      const response = await fetch(`${url}/api/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: imageData,
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error.message || "Image upload failed.");
      }

      // Assuming the file ID is returned in the result
      return result[0].id;
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Error uploading image: " + error.message);
      throw error;
    }
  };

  // Student data upload function
  const uploadStudentData = async (imageId) => {


    const prepareDataForStrapi = (data) => {
      const preparedData = { ...data };
      const relationalFields = ["course", "stream", "semester", "studentOf"];

      relationalFields.forEach((field) => {
        if (preparedData[field]) {
          preparedData[field] = { connect: [preparedData[field]] };
        } else {
          delete preparedData[field];
        }
      });

      return preparedData;
    };

    const dataToSend = {
      data: {
        ...prepareDataForStrapi(formData),
        photo: imageId,
      },
    };

    // const dataToSend = {
    //   data: {
    //     ...formData,
    //     photo: imageId,
    //   },
    // };

    try {
      console.log(0);
      const response = await fetch(`${url}/api/students`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(dataToSend),
      });

      const result = await response.json();

      if (!response.ok) {
        try {
          const response = await fetch(`${url}/api/upload/files/${imageId}`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
        } catch (error) {
          console.log(error);
        }
        throw new Error(result.error.message || "Student data upload failed.");
      }

      console.log(result);
      alert("Registration successfull");
    } catch (error) {
      console.error("Error uploading student data:", error);
      alert("Error uploading student data: " + error.message);
    }
  };

  // Main register student function
  const registerStudent = async (e) => {
    e.preventDefault();

    try {
      let imageId = null;
      if (selectedImage) {
        imageId = await uploadImage(); // Upload the image first
      }
      await uploadStudentData(imageId); // Upload student data with image ID
    } catch (error) {
      console.log("Error during registration:", error);
    }
  };

  return (
    <div className="w-full bg-gray-200 box-border">
      <div className="m-4 bg-white p-2 h-full rounded-lg shadow-sm ">
        <form onSubmit={registerStudent}>
          {/* Section One*/}
          <div className="block p-4 m-2 bg-green-200 rounded-lg font-bold text-green-600 border border-green-700">
            Personal Details
          </div>

          <div className="flex">
            <div className="w-4/5">
              <div className="flex w-full">
                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="studentName" className="p-1 font-semibold">
                    Name of the Candidate
                  </label>
                  <input
                    type="text"
                    id="studentName"
                    name="studentName"
                    className="w-3/4 block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    placeholder="Applicant Name"
                    value={formData.studentName}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="fatherName" className="p-1 font-semibold">
                    Father's Name
                  </label>
                  <input
                    type="text"
                    id="fatherName"
                    name="fatherName"
                    className="w-3/4 block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    placeholder="Father's Name"
                    value={formData.fatherName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="flex w-full">
                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="motherName" className="p-1 font-semibold">
                    Mother's Name
                  </label>
                  <input
                    type="text"
                    id="motherName"
                    name="motherName"
                    className="w-3/4 block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    placeholder="Mother's Name"
                    value={formData.motherName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="dateOfBirth" className="p-1 font-semibold">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    className="w-11/12 border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded font-sm"
                    placeholder="As per matriculation certificate (dd-mm-yyyy)"
                    value={formData.dateOfBirth}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
            </div>
            {/* <div className="w-1/5">
              <div className="h-32 w-36 m-auto shadow-sm bg-gray-300 mt-2">
                12
              </div>
              <p className="w-36 m-auto text-center font-semibold bg-blue-500 text-white border tracking-wider">
                Upload
              </p>
              <p className="px-2 text-sm">Photo size: 90x120 Pixels</p>
            </div> */}
            <div className="w-1/5">
              <div className="h-32 w-36 m-auto shadow-sm bg-gray-300 mt-2">
                {previewUrl ? (
                  <img
                    src={previewUrl}
                    alt="Preview"
                    className="h-full w-full object-cover"
                  />
                ) : (
                  <span className="flex items-center justify-center h-full">
                    No Image
                  </span>
                )}
              </div>

              <div className="w-36 m-auto mt-1 flex flex-col items-center">
                <input
                  type="file"
                  id="imageUpload"
                  name="imageUpload"
                  accept="image/jpeg"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <button
                  type="button"
                  onClick={() => document.getElementById("imageUpload").click()}
                  className="cursor-pointer bg-blue-500 text-white px-4 py-2 rounded text-sm w-36"
                >
                  Choose File
                </button>
              </div>
              <p className="px-2 text-xs mt-1">
                Photo size: 90x120 Pixels (JPEG only)
              </p>
            </div>
          </div>

          {/* Section Two */}

          <div className="flex mt-8">
            <div className="w-1/2">
              <div className="flex w-full">
                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="gender" className="p-1 font-semibold">
                    Gender
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                    value={formData.gender}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      Select Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="category" className="p-1 font-semibold">
                    Category
                  </label>
                  <select
                    id="category"
                    name="category"
                    className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                    value={formData.category}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      Select Category
                    </option>
                    <option value="General">General</option>
                    <option value="SC">SC</option>
                    <option value="ST">ST</option>
                    <option value="OBC">OBC</option>
                  </select>
                </div>
              </div>

              <div className="flex w-full">
                <div className="w-full mx-4 py-2">
                  <label htmlFor="isEmployed" className="p-1 font-semibold">
                    Are you employed?
                  </label>
                  <select
                    id="isEmployed"
                    name="isEmployed"
                    className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                    value={formData.isEmployed}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      Select Option
                    </option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex w-full">
                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="aadhaarNumber" className="p-1 font-semibold">
                    Aadhaar Number
                  </label>
                  <input
                    type="text"
                    id="aadhaarNumber"
                    name="aadhaarNumber"
                    className="w-full block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                    placeholder="Aadhar Number"
                    value={formData.aadhaarNumber}
                    onChange={(e) => {
                      const newValue = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 12);
                      setFormData({ ...formData, aadhaarNumber: newValue });
                    }}
                    maxLength="12"
                    pattern="\d{12}"
                    title="Aadhaar number must be exactly 12 digits"
                    required
                  />
                </div>

                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="passportNo" className="p-1 font-semibold">
                    Passport No
                  </label>
                  <input
                    type="text"
                    id="passportNo"
                    name="passportNo"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    placeholder="Passport No"
                    value={formData.passportNo}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex w-full">
                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="employerName" className="p-1 font-semibold">
                    Employer Name
                  </label>
                  <input
                    type="text"
                    id="employerName"
                    name="employerName"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    placeholder="Employer Name"
                    value={formData.employerName}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="designation" className="p-1 font-semibold">
                    Designation
                  </label>
                  <input
                    type="text"
                    id="designation"
                    name="designation"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    placeholder="Designation"
                    value={formData.designation}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Section Three */}
          <div className="block p-4 m-2 bg-red-100 rounded-lg font-bold text-red-800 my-8 border border-red-900">
            Personal Details
          </div>
          <div className="flex">
            <div className="w-1/2">
              <div className="flex w-full">
                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="contactNumber" className="p-1 font-semibold">
                    Contact Number
                  </label>
                  <input
                    type="text"
                    id="contactNumber"
                    name="contactNumber"
                    className="w-full block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                    placeholder="Contact Number"
                    value={formData.contactNumber}
                    onChange={(e) => {
                      const newValue = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 10);
                      setFormData({ ...formData, contactNumber: newValue });
                    }}
                    maxLength="10"
                    pattern="\d{10}"
                    title="Contact number must be exactly 10 digits"
                    required
                  />
                </div>

                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="emailAddress" className="p-1 font-semibold">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="emailAddress"
                    name="emailAddress"
                    className="w-full block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                    placeholder="Email Address"
                    value={formData.emailAddress}
                    onChange={handleInputChange}
                    required
                    pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                    title="Please enter a valid email address"
                  />
                </div>
              </div>

              <div className="flex w-full">
                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="country" className="p-1 font-semibold">
                    Country
                  </label>
                  <select
                    id="country"
                    name="country"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    value={formData.country}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      Select Country
                    </option>
                    <option value="India">India</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="nationality" className="p-1 font-semibold">
                    Nationality
                  </label>

                  <select
                    id="nationality"
                    name="nationality"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    value={formData.nationality}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      Select Nationality
                    </option>
                    <option value="Indian">Indian</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex w-full">
                <div className="w-1/2 mx-4 py-2">
                  <label
                    htmlFor="fathersContactNo"
                    className="p-1 font-semibold"
                  >
                    Father's Contact No
                  </label>
                  <input
                    type="text"
                    id="fathersContactNo"
                    name="fathersContactNo"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    placeholder="Father's Contact No"
                    value={formData.fathersContactNo}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="w-1/2 mx-4 py-2">
                  <label
                    htmlFor="mothersContactNo"
                    className="p-1 font-semibold"
                  >
                    Mother's Contact No
                  </label>
                  <input
                    type="text"
                    id="mothersContactNo"
                    name="mothersContactNo"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    placeholder="Mother's Contact No"
                    value={formData.mothersContactNo}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex w-full">
                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="state" className="p-1 font-semibold">
                    State
                  </label>
                  <select
                    name="state"
                    id="state"
                    onChange={handleInputChange}
                    value={formData.state}
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    required
                  >
                    <option value="" disabled selected>
                      Select State
                    </option>
                    {statesdata.map((statesdata) => (
                      <option key={statesdata.name} value={statesdata.name}>
                        {statesdata.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="district" className="p-1 font-semibold">
                    District
                  </label>
                  <input
                    type="text"
                    id="district"
                    name="district"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    placeholder="District"
                    value={formData.district}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-3/4 mx-4 py-2">
              <label htmlFor="address" className="p-1 font-semibold">
                Address
              </label>
              <input
                type="text"
                id="address"
                name="address"
                className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                placeholder="Address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </div>
            <div className="w-1/4 mx-4 py-2">
              <label htmlFor="pinCode" className="p-1 font-semibold">
                Pin Code
              </label>
              <input
                type="text"
                id="pinCode"
                name="pinCode"
                className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                placeholder="Pin Code"
                value={formData.pinCode}
                onChange={handleInputChange}
              />
            </div>
          </div>
          {/* Section Four */}
          <div className="block p-4 m-2 bg-red-100 rounded-lg font-bold text-red-800 my-8 border border-red-900">
            Previous Qualification Details
          </div>

          <div className="mx-4 py-2 border">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs  text-gray-500 uppercase tracking-wider font-extrabold">
                    Examination
                  </th>
                  <th className="px-6 py-3 text-left text-xs  text-gray-500 uppercase tracking-wider font-extrabold">
                    Year of Passing
                  </th>
                  <th className="px-6 py-3 text-left text-xs  text-gray-500 uppercase tracking-wider font-extrabold">
                    Board
                  </th>
                  <th className="px-6 py-3 text-left text-xs  text-gray-500 uppercase tracking-wider font-extrabold">
                    CGPA
                  </th>
                  <th className="px-6 py-3 text-left text-xs  text-gray-500 uppercase tracking-wider font-extrabold">
                    Subject
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap font-semibold tracking-tighter">
                    Secondary
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="date"
                      id="examSecondaryYearOfPassing"
                      name="examSecondaryYearOfPassing"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      placeholder="yyyy"
                      value={formData.examSecondaryYearOfPassing}
                      onChange={handleInputChange}
                      required
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      id="examSecondaryBoardUniversity"
                      name="examSecondaryBoardUniversity"
                      className="w-full block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examSecondaryBoardUniversity}
                      onChange={handleInputChange}
                      required
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="number"
                      id="examSecondaryPercentage"
                      name="examSecondaryPercentage"
                      className="w-full block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examSecondaryPercentage}
                      onChange={handleInputChange}
                      min="1"
                      max="100"
                      required
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      id="secondarysubject"
                      name="examSecondarySubjects"
                      className="w-full block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examSecondarySubjects}
                      onChange={handleInputChange}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap font-semibold tracking-tighter">
                    Sr. Secondary
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="date"
                      id="examSrSecondaryYearOfPassing"
                      name="examSrSecondaryYearOfPassing"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      placeholder="yyyy"
                      value={formData.examSrSecondaryYearOfPassing}
                      onChange={handleInputChange}
                      required
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      id="examSrSecondaryBoardUniversity"
                      name="examSrSecondaryBoardUniversity"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examSrSecondaryBoardUniversity}
                      onChange={handleInputChange}
                      required
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="number"
                      id="examSrSecondaryPercentage"
                      name="examSrSecondaryPercentage"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examSrSecondaryPercentage}
                      onChange={handleInputChange}
                      max={100}
                      min={1}
                      required
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      id="secondarysubject"
                      name="examSrSecondarySubjects"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examSrSecondarySubjects}
                      onChange={handleInputChange}
                      required
                    />
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap font-semibold tracking-tighter">
                    Graduation
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="date"
                      id="secondaryyear"
                      name="examGraduationYearOfPassing"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      placeholder="yyyy"
                      value={formData.examGraduationYearOfPassing}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      id="secondaryboard"
                      name="examGraduationBoardUniversity"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examGraduationBoardUniversity}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      id="secondarycgpa"
                      name="examGraduationPercentage"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examGraduationPercentage}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      id="secondarysubject"
                      name="examGraduationSubjects"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examGraduationSubjects}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap font-semibold tracking-tighter">
                    Post Graduation
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="date"
                      id="examPostGraduationYearOfPassing"
                      name="examPostGraduationYearOfPassing"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      placeholder="yyyy"
                      value={formData.examPostGraduationYearOfPassing}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      id="examPostGraduationBoardUniversity"
                      name="examPostGraduationBoardUniversity"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examPostGraduationBoardUniversity}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      id="examPostGraduationPercentage"
                      name="examPostGraduationPercentage"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examPostGraduationPercentage}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      id="secondarysubject"
                      name="examPostGraduationSubjects"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examPostGraduationSubjects}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap font-semibold tracking-tighter">
                    Others
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="date"
                      id="secondaryyear"
                      name="examOthersYearOfPassing"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      placeholder="yyyy"
                      value={formData.examOthersYearOfPassing}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      id="secondaryboard"
                      name="examOthersBoardUniversity"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examOthersBoardUniversity}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      id="secondarycgpa"
                      name="examOthersPercentage"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examOthersPercentage}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="text"
                      id="secondarysubject"
                      name="examOthersSubjects"
                      className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                      value={formData.examOthersSubjects}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Section Fivth */}

          <div className="block p-4 m-2 bg-blue-100 rounded-lg font-bold text-blue-900 my-8 border border-blue-900">
            Programme Details
          </div>
          <div className="flex mx-4 py-2">
            <div className="w-1/4">
              <label htmlFor="session" className="p-1 font-semibold">
                Session
              </label>
              <select
                id="session"
                name="session"
                className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                value={formData.session}
                onChange={handleInputChange}
              >
                <option value="" disabled selected>
                  Select Session
                </option>
                {session &&
                  session.map((sessiondata) => (
                    <option
                      key={sessiondata.documentId}
                      value={sessiondata.documentId}
                    >
                      {sessiondata.Session}
                    </option>
                  ))}
              </select>
            </div>
            <div className="w-1/4">
              <label htmlFor="modeOfStudy" className="p-1 font-semibold">
                Mode of Study
              </label>
              <select
                id="modeOfStudy"
                name="modeOfStudy"
                className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                value={formData.modeOfStudy}
                onChange={handleInputChange}
                required
              >
                <option value="" selected disabled>
                  Select
                </option>
                <option value="Regular">Regular</option>
                <option value="Distance">Distance</option>
              </select>
            </div>
            <div className="w-1/4">
              <label htmlFor="admissionType" className="p-1 font-semibold">
                Admission Type
                <span className="text-red-400 font-semibold">*</span>
              </label>
              <select
                id="admissionType"
                name="admissionType"
                className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                value={formData.admissionType}
                onChange={handleInputChange}
              >
                <option value="Normal" selected>
                  Normal
                </option>
                <option value="Lateral Entry">Lateral Entry</option>
                <option value="Migrated">Migrated</option>
              </select>
            </div>
            <div className="w-1/4">
              <label htmlFor="hostelFacility" className="p-1 font-semibold">
                Hostel Facility
              </label>
              <select
                id="hostelFacility"
                name="hostelFacility"
                className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                value={formData.hostelFacility}
                onChange={handleInputChange}
              >
                <option value={true} selected>
                  Hostel Facility Required
                </option>
                <option value={false}>No Facility Required</option>
              </select>
            </div>
          </div>
          <div className="flex mx-4 py-2">
            <div className="w-1/3">
              <label htmlFor="course" className="p-1 font-semibold">
                Course
              </label>
              <select
                id="course"
                name="course"
                className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                value={formData.course}
                onChange={handleInputChange}
              >
                <option value="" disabled selected>
                  Select Course
                </option>
                {courses &&
                  courses.map((coursedata) => (
                    <option
                      key={coursedata.documentId}
                      value={coursedata.documentId}
                    >
                      {coursedata.course_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="w-1/3">
              <label htmlFor="stream" className="p-1 font-semibold">
                Stream
              </label>
              <select
                id="stream"
                name="stream"
                className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                value={formData.stream}
                onChange={handleInputChange}
              >
                {stream && stream.length > 0 ? (
                  stream.map((stream) => (
                    <option key={stream.documentId} value={stream.documentId}>
                      {stream.stream_name}
                    </option>
                  ))
                ) : (
                  <option disabled>No streams available</option>
                )}
              </select>
            </div>
            <div className="w-1/3">
              <label htmlFor="semester" className="p-1 font-semibold">
                semester
              </label>
              <select
                id="semester"
                name="semester"
                className="w-3/4 block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                value={formData.semester}
                onChange={handleInputChange}
              >
                <option value="" selected disabled>
                  select
                </option>
                {/* <option value="semester_2">semester_2</option>
                <option value="semester_3">semester_3</option> */}
              </select>
            </div>
          </div>
          {/* Section Six */}

          <div className="block p-4 m-2 bg-red-200 rounded-lg font-bold text-red-900 my-8 border border-red-900">
            Fee Details
          </div>
          <div className="flex">
            <div className="w-1/2">
              <div className="flex w-full">
                <div className="w-1/2 mx-4 py-2">
                  <label
                    htmlFor="registrationFee"
                    className="p-1 font-semibold"
                  >
                    Registration Fee
                  </label>
                  <input
                    type="text"
                    id="registrationFee"
                    name="registrationFee"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    onChange={handleInputChange}
                    value={formData.registrationFee}
                  />
                </div>

                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="entranceFee" className="p-1 font-semibold">
                    Entrance Fee
                  </label>
                  <input
                    type="text"
                    id="entranceFee"
                    name="entranceFee"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    value={formData.entranceFee}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="flex w-full">
                <div className="w-1/2 mx-4 py-2">
                  <label
                    htmlFor="universityDevelopmentFee"
                    className="p-1 font-semibold"
                  >
                    University Development Fee
                  </label>
                  <input
                    type="text"
                    id="universityDevelopmentFee"
                    name="universityDevelopmentFee"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    value={formData.universityDevelopmentFee}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="hostelFee" className="p-1 font-semibold">
                    Hostel Fee
                  </label>
                  <input
                    type="text"
                    id="hostelFee"
                    name="hostelFee"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    value={formData.hostelFee}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="flex w-full">
                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="enrollmentFee" className="p-1 font-semibold">
                    Enrollment Fee
                  </label>
                  <input
                    type="text"
                    id="enrollmentFee"
                    name="enrollmentFee"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    value={formData.enrollmentFee}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="tuitionFee" className="p-1 font-semibold">
                    Tuition Fee
                  </label>
                  <input
                    type="text"
                    id="tuitionFee"
                    name="tuitionFee"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    value={formData.tuitionFee}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex w-full">
                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="lateFee" className="p-1 font-semibold">
                    Late Fee
                  </label>
                  <input
                    type="text"
                    id="lateFee"
                    name="lateFee"
                    className="w-full block border border-grey-400 m-1 focus:outline-none focus:!border-red-500 p-1 px-2 rounded"
                    value={formData.lateFee}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="w-1/2 mx-4 py-2">
                  <label htmlFor="totalDeposit" className="p-1 font-semibold">
                    Total Deposit
                  </label>
                  <input
                    type="text"
                    id="totalDeposit"
                    name="totalDeposit"
                    className="w-full block border border-grey-400 m-1 focus:outline-none  focus:!border-red-500 p-1 px-2 rounded"
                    value={formData.totalDeposit}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Section Seven */}

          <div className="block p-4 m-2 bg-blue-100 rounded-lg font-bold text-blue-700 my-8 border border-blue-900">
            Previous Qualification Details
          </div>
          <div className="mx-4 py-2">
            <p className="font-semibold text-lg track text-justify px-4">
              I hereby declare that entries made by me in this admission form
              and the documents submitted by me along with it, are true to the
              best of my knowledge, in all respects and in any case, if any
              information is found to be false, this shall entail automatic
              cancellation of my admission and forfeiture of all fee deposited,
              besides rendering me liable to such action as the University may
              deem proper.
            </p>
            <br />
            <p className="font-semibold text-lg track text-justify px-4">
              I take note that my admission to the University and continuation
              on its roll are subject to the provisions of rules of the
              University, issued from time to time. I shall abide by the rules
              of discipline and proper conduct. I am fully aware of the law
              regarding ragging as well as the punishment and that if, found
              guilty on this account I am liable to be punished appropriately. I
              hereby undertake that I shall not indulge in any act of ragging.
            </p>
            <br />
            <p className="font-semibold text-lg track text-justify px-4">
              In such circumstances, I will have no claim for refund of fees
              deposited by me the University.
            </p>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="text-white bg-blue-400 p-2 font-semibold rounded-sm"
            >
              Submit my application
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegistrationFromStudent;
