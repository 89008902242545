import React from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className="bg-gradient-to-b from-blue-600 to-blue-400 text-white w-72 h-full p-4 shadow-lg">
      <ul className="space-y-4">
        <li className="hover:bg-yellow-400 hover:text-blue-900 hover:underline transition duration-300 ease-in-out p-2 rounded-lg font-bold cursor-pointer">
          <NavLink to="/auth/dashboard">Dashboard</NavLink>
        </li>
        <li className="hover:bg-yellow-300 hover:text-blue-900 transition duration-300 ease-in-out p-2 rounded-lg font-bold cursor-pointer">
          Student Manager
          <ul className="ml-4 mt-2 space-y-2">
            <li className="hover:bg-yellow-400 hover:text-blue-900 hover:underline transition duration-300 ease-in-out p-2 rounded-lg font-bold cursor-pointer">
              <NavLink to="/auth/onlineregistration">Registration Form</NavLink>
            </li>
            <li className="hover:bg-yellow-400 hover:text-blue-900 hover:underline transition duration-300 ease-in-out p-2 rounded-lg font-bold cursor-pointer">
              <NavLink to="/auth/searchstudent">Student Search</NavLink>
            </li>
          </ul>
        </li>
        <li className="hover:bg-yellow-300 hover:text-blue-900 transition duration-300 ease-in-out p-2 rounded-lg font-bold cursor-pointer">
          Sub Center Manager
          <ul className="ml-4 mt-2 space-y-2">
            <li className="hover:bg-yellow-400 hover:text-blue-900 transition duration-300 ease-in-out p-2 rounded-lg font-bold cursor-pointer">
              <NavLink to="/auth/subcentermanage">Manage Sub Center</NavLink>
            </li>
          </ul>
        </li>
        <li className="hover:bg-yellow-400 hover:text-blue-900 transition duration-300 ease-in-out p-2 rounded-lg font-bold cursor-pointer">
          <NavLink to="/auth/wallet">My Wallet</NavLink>
        </li>
        <li className="hover:bg-yellow-400 hover:text-blue-900 transition duration-300 ease-in-out p-2 rounded-lg font-bold cursor-pointer">
          <NavLink to="/auth/changepassword">Change Password</NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;