import { useEffect, useState } from "react";
import ApiContext from "./ApiContext";
import { toast } from "react-toastify";

const ApiState = (props) => {
  const url = "http://localhost:1337";  

  const token = localStorage.getItem("token");
  const [SubCenterData, setSubCenterData] = useState([]);
  const [session, setSessions] = useState(null);
  const [courses, setCourses] = useState(null);


  const statesdata = [
    { name: "Andhra Pradesh" },
    { name: "Arunachal Pradesh" },
    { name: "Assam" },
    { name: "Bihar" },
    { name: "Chhattisgarh" },
    { name: "Goa" },
    { name: "Gujarat" },
    { name: "Haryana" },
    { name: "Himachal Pradesh" },
    { name: "Jharkhand" },
    { name: "Karnataka" },
    { name: "Kerala" },
    { name: "Madhya Pradesh" },
    { name: "Maharashtra" },
    { name: "Manipur" },
    { name: "Meghalaya" },
    { name: "Mizoram" },
    { name: "Nagaland" },
    { name: "Odisha" },
    { name: "Punjab" },
    { name: "Rajasthan" },
    { name: "Sikkim" },
    { name: "Tamil Nadu" },
    { name: "Telangana" },
    { name: "Tripura" },
    { name: "Uttar Pradesh" },
    { name: "Uttarakhand" },
    { name: "West Bengal" },
    { name: "Andaman and Nicobar Islands" },
    { name: "Chandigarh" },
    { name: "Dadra and Nagar Haveli and Daman and Diu" },
    { name: "Lakshadweep" },
    { name: "Delhi" },
    { name: "Puducherry" },
    { name: "Ladakh" },
    { name: "Jammu and Kashmir" },
  ];



  // Login Function
    const loginFunc = async (identifier, password) => {
      try {
        const response = await fetch(`${url}/api/auth/local`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ identifier, password }),
        });
        
        return response;
      } catch (err) {
        toast.error("Internal Server Error");
        console.log(err);
      }
    };

    // Verify Token

    const VerifyToken = async (token) => {
      try {
        const response = await fetch(`${url}/api/users/me`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        return response;
      } catch (error) {
        console.error("Error verifying token:", error);
        return null; // Return null in case of an error
      }
    };

    // Session
    const Session = async () => {
      try {
        const response = await fetch(`${url}/api/sessions`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          },
        });
        const data = await response.json();
        setSessions(data.data);
      } catch (error) {
        console.log(error);
      }
    }

    // Course
    const Course = async () => {
      try {
        const response = await fetch(`${url}/api/courses`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          },
        });
        const data = await response.json();
        setCourses(data.data);
        console.log(data.data);
      } catch (error) {
        console.log(error);
      }
    }



  const fetchSubCenterData = async () => {
    const response = await fetch(`${url}/api/informationSubCenter`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    setSubCenterData(data);
  };




  useEffect(() => {
    // fetchSubCenterData();
    Session();
    Course();
  }, []);

  return (
    <ApiContext.Provider
      value={{
        url,
        SubCenterData,
        token,
        VerifyToken,
        loginFunc,
        session,
        statesdata,
        courses,
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};

export default ApiState;